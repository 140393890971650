
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'
const getUnlockPromotionId = (addonList) => addonList?.map(item => item.promotionId || item.couponCode).join(',') || '-'

export const sendSaExposeScenesabtByUnlockPromotion = ({ addonList = [] }) => {
  exposeScenesAbtAggregator.addEvent({
    daId: '1-8-7-60',
    extraData: {
      scenes: 'unlock_promotion',
      unlock_promotion_id: getUnlockPromotionId(addonList),
    }
  })
}

export const sendSaExposeScenesabtByDetailTips = ({ type = '' }) => {
  exposeScenesAbtAggregator.addEvent({
    daId: '1-8-7-60',
    extraData: {
      scenes: 'promotiondetails_detail_tips',
      type,
    }
  })
}

export const getSaParamsExposePopUnclokPromos = ({ saData = {}, addonList = [] }) => {
  return {
    pop_type: saData.scene || 'estimate', // discount
    sku_code: saData.id,
    goods_id: saData.goods_id,
    unlock_promotion_id: getUnlockPromotionId(addonList),
  }
}

export const sendSaClickPopUnclokpromos = ({ saData = {}, addonItem = {} }) => {
  daEventCenter.triggerNotice({
    daId: '1-8-3-83',
    extraData: {
      sku_code: saData.id,
      goods_id: saData.goods_id,
      unlock_promotion_id: addonItem.promotionId || addonItem.couponCode || '-',
    }
  })
}

export const sendExposeCartBundling = ({ twoLevelData = {} } = {}) => {
  daEventCenterV2.triggerNotice({
    daId: 'expose_cart_bundling.comp_discount-details',
    data: {
      promotion_type_id: twoLevelData.typeId || '-',
      promotion_id: twoLevelData.promotionId || twoLevelData.couponCode || '-',
    }
  })
}

export const sendExposeCartBundlingGuide = ({ twoLevelData = {} } = {}) => {
  daEventCenterV2.triggerNotice({
    daId: 'expose_cart_bundling_guide.comp_discount-details',
    data: {
      promotion_type_id: twoLevelData.typeId || '-',
      promotion_id: twoLevelData.promotionId || twoLevelData.couponCode || '-',
    }
  })
}

export const sendSaExposeScenesabtByCartBundling = ({ cartBundlingBiData }) => {
  exposeScenesAbtAggregator.addEvent({
    daId: '1-8-7-60',
    extraData: {
      ...cartBundlingBiData,
    }
  })
}

export const sendSaClickCartBundling = ({ saData = {}, twoLevelData = {} } = {}) => {
  daEventCenterV2.triggerNotice({
    daId: 'click_cart_bundling.comp_discount-details',
    extraData: {
      promotion_type_id: twoLevelData.typeId || '-',
      promotion_id: twoLevelData.promotionId || twoLevelData.couponCode || '-',
      button_type: saData.button_type,
    }
  })
}

export const sendSaClickCartBundlingGuide = ({ saData = {}, twoLevelData = {} } = {}) => {
  daEventCenterV2.triggerNotice({
    daId: 'click_cart_bundling_guide.comp_discount-details',
    extraData: {
      promotion_type_id: twoLevelData.typeId || '-',
      promotion_id: twoLevelData.promotionId || twoLevelData.couponCode || '-',
      button_type: saData.button_type,
    }
  })
}
