<template>
  <div
    class="add-on-wrapper"
  >
    <AddOnBanner
      v-if="showBanner"
      :bottomView="bottomView"
      @sendSaClick="handleSaClick"
      @clickClose="updateNotClosed"
      @clickView="updateNotViewd"
    />
    <AddOnEntry
      :data="data"
      @sendSaClick="handleSaClick"
    />
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, computed, watch } from 'vue'
import AddOnBanner from './AddOnBanner.vue'
import AddOnEntry from './AddOnEntry.vue'
import { sendExposeCartBundlingGuide, sendExposeCartBundling, sendSaClickCartBundling, sendSaClickCartBundlingGuide } from 'public/src/pages/cart_v2/components/discountDetailDrawer/utils/analysis.js'
import { useBooleanState } from 'public/src/pages/cart_v2/hooks/useBooleanState.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
    required: true,
  },
  bottomView: {
    type: Object,
    default: () => {},
    required: true,
  }
})

const { state } = useStore()
const notReportBanner = ref(window.addOnEntry_notReportBanner ?? true)
const notReportEntry = ref(window.addOnEntry_notReportEntry ?? true)
const { state: notViewed, update: updateNotViewd } = useBooleanState(true, 'cart_addOnEntry_notViewed', 'local')
const { state: notClosed, update: updateNotClosed } = useBooleanState(true, 'cart_addOnEntry_notClosed', 'session')

let observer = null
const triggerCount = ref(0)
const entryInViewport = ref(true)
const showBanner = computed(() => notViewed.value && notClosed.value && !entryInViewport.value && !!props.bottomView?.btnText)
const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)

watch(() => showDiscountDetails.value, (n) => {
  if (!n) {
    window.addOnEntry_notReportBanner = true
    window.addOnEntry_notReportEntry = true
  }
})

const handleShowBanner = () => {
  const addOnEntryElem = document.querySelector('.j-add-on-entry')
  if (!addOnEntryElem) return
  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const isExpose = entry.isIntersecting
      if (triggerCount.value === 0) {
        entryInViewport.value = isExpose
      } else if (!entryInViewport.value && isExpose) {
        entryInViewport.value = true
      }
      if (isExpose) {
        observer?.disconnect()
      }
      handleExpose(isExpose)
      triggerCount.value++
    }, {
      threshold: [1]
    })
  })
  observer.observe(addOnEntryElem)
}

const handleExpose  = (isExpose) => {
  const [twoLevelData] = props.data?.twoLevelData || []
  if (!isExpose) {
    showBanner.value && handleSendExposeCartBundlingGuide(twoLevelData)
  } else if(isExpose) {
    handleSendExposeCartBundling(twoLevelData)
  }
}
const handleSendExposeCartBundlingGuide = (twoLevelData) => {
  if (notReportBanner.value) {
    sendExposeCartBundlingGuide({ twoLevelData })
    window.addOnEntry_notReportBanner = false
    notReportBanner.value = false
  }
}
const handleSendExposeCartBundling = (twoLevelData) => {
  if (notReportEntry.value) {
    sendExposeCartBundling({ twoLevelData })
    window.addOnEntry_notReportEntry = false
    notReportEntry.value = false
  }
}
const handleSaClick = ({ daId, saData }) => {
  const [twoLevelData] = props.data?.twoLevelData || []
  if (daId === 'click_cart_bundling') {
    sendSaClickCartBundling({
      saData,
      twoLevelData,
    })
  }
  if (daId === 'click_cart_bundling_guide') {
    sendSaClickCartBundlingGuide({
      saData,
      twoLevelData,
    })
  }
}
onMounted(() => {
  setTimeout(handleShowBanner, 300)
})
onUnmounted(() => {
  observer?.disconnect()
})
</script>

<style lang="less" scoped>
.add-on-wrapper {
  padding-top: 12/37.5rem;
  padding-bottom: 42/37.5rem;
  border-top: 0.5px solid #E5E5E5;
}
</style>
