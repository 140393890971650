<template>
  <SSticky :type="isSticky ? 'sticky' : ''">
    <div class="discount-top">
      <span class="select-num">
        {{ info.selectNumDesc }}
      </span>
      <div class="right">
        <SAdapterText
          v-if="showStyle == 0 && soldOutLure"
          min-size="10"
          :style="{ color: soldOutLure.textColor }"
          :text="soldOutLure.text"
        />
        <swiper-container
          v-show="showStyle == 1 && lureList.length"
          ref="swiperRef"
          class="swiper-box"
          init="false"
          direction="vertical"
          autoplay-disable-on-interaction="false"
          allow-touch-move="false"
          observer="true"
          observe-parents="true"
          :dir="locals.GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="item in lureList"
            :key="item.type"
          >
            <div class="induce-item">
              <img 
                width="14" 
                height="14" 
                :src="item.prefixIcon" 
                alt=""
              />
              <SAdapterText
                class="tip"
                min-size="10"
                :style="{ color: item.textColor }"
                :text="item.text"
              />
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </SSticky>
</template>
<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { SSticky } from '@shein-aidc/sui-sticky/mobile'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import { Autoplay } from 'swiper/modules'
import { register } from 'swiper/element'
if (typeof window !== 'undefined') {
  register()
}

const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({}),
  },
  showStyle: {
    type: [Number, String],
    default: '',
  },
})

const swiperRef = ref(null)

const isSticky = computed(() => {
  return props.showStyle == 1
})
const lureList = computed(() => {
  return props.info?.lureInfo?.lureList || []
})
const soldOutLure = computed(() => {
  return props.info?.lureInfo?.lureList?.find(v => v.type == 1)
})

onMounted(() => {
  if (props.showStyle == 1) {
    initSwiper()
  }
})

const initSwiper = () => {
  nextTick(() => {
    const options = {
      modules: [Autoplay],
      autoplay: { delay: 1500 },
      loop: true,
    }
    
    Object.assign(swiperRef.value, options)
    swiperRef.value?.initialize()
  })
}

</script>
<style lang="less" scoped>
.discount-top {
  padding: 6/37.5rem 12/37.5rem 12/37.5rem;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  .select-num {
    margin-right: 4/37.5rem;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: normal;
  }
  .right {
    flex: 1;
    overflow: hidden;
    height: 14px;
    line-height: 14px;
    .swiper-box {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
    }
    .induce-item {
      width: 100%;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      /* stylelint-disable-next-line selector-max-specificity */
      .tip {
        padding-left: 2/37.5rem;
      }
    }
  }
}
</style>
