import { onBeforeMount, ref } from 'vue'

export function useBooleanState(initialValue, key, storageType = 'session') {
  const state = ref(initialValue)
  
  const getKey = () => {
    if (typeof key === 'function') {
      return key()
    }
    return key
  }
  const storage = () => storageType === 'session' ? sessionStorage : storageType === 'local' ? localStorage : sessionStorage
  const update = (value = false) => {
    state.value = value
    storage().setItem(getKey(), value)
  }
  const syncCache = () => {
    const storedValue = storage().getItem(getKey())
    if (storedValue !== null) {
      state.value = storedValue === 'true'
    }
  }

  onBeforeMount(syncCache)

  return {
    state,
    update,
  }
}
