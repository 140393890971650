<template>
  <div
    class="add-on-entry j-add-on-entry"
  >
    <div class="add-on-entry__title">
      {{ data.leftKeyTip }}
    </div>
    <div
      class="add-on-entry__item"
      @click="handleAdd('body')"
    >
      <div
        class="add-on-entry__desc"
      >
        <img
          class="img"
          :src="twoLevelData.icon"
        />
        <span
          class="text"
          v-html="twoLevelData.leftTip"
        >
        </span>
      </div>
      <div
        class="add-on-entry__btn"
        @click.stop="handleAdd('add')"
      >
        <ResizeFont>
          <ResizeFontText
            :initFontSize="11"
            :resizeFontMin="10"
            :resize-font-step="1"
          >
            {{ twoLevelData.rightTip }}
          </ResizeFontText>
        </ResizeFont>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick } from 'vue'
import ResizeFont from 'public/src/pages/cart_v2/components/common/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/cart_v2/components/common/ResizeFontText.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const emit = defineEmits(['sendSaClick'])

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    required: true,
  }
})

const { state, commit } = useStore()
const AddItem = useCartAddItem()
const twoLevelData = computed(() => props.data?.twoLevelData?.[0] ?? {})
const cartAddItemData = computed(() => state.cartAddItemPopupInfoList.find(item => {
  if (item.activityType === twoLevelData.value.mixType && (item.activityId === twoLevelData.value.couponCode || item.activityId === twoLevelData.value.promotionId)) return true
  return false
}) ?? {})

const handleAdd = (button_type) => {
  const popupType = cartAddItemData.value.cartAddItemEvokePopupType
  if (popupType === 'allInActivity') {
    const getMixType = (addItemType) => {
      if (addItemType === 3) {
        return 'coupon'
      } else if (addItemType) {
        return 'promotion'
      }
      return ''
    }
    const mixType = getMixType(cartAddItemData.value?.allInActivityData?.addItemType)
    const activityId = cartAddItemData.value.allInActivityData.popupInfoId
    const couponOpts = {
      coupon: activityId,
      state: 'coupon_add_cartbundling',
    }
    const promotionOpts = {
      popupInfoId: activityId,
      state: 'promotion_add_cartbundling',
    }
    const options = mixType === 'coupon' ? couponOpts : mixType === 'promotion' ? promotionOpts : null
    const evetns = {
      viewCart({ is_satisfied } = {}) {
        nextTick(() => {
          const isDone = is_satisfied > 0
          commit('changeAllDiscountDetailDrawerParams', {
            show: true,
            ...(isDone ? {
              anchorType: mixType,
              anchorId: activityId,
            } : {})
          })
        })
      }
    }
    options && AddItem.show(options, evetns)
  } else if (popupType === 'noCheckedInCart') {
    commit('changeCartAddBundlingState', {
      show: true,
      activityId: cartAddItemData.value.activityId,
    })
  }
  sendSaClick(button_type)
}
const sendSaClick = (button_type) => {
  emit('sendSaClick', {
    daId: 'click_cart_bundling',
    saData: {
      button_type,
    }
  })
}
</script>

<style lang="less" scoped>
.add-on-entry {
  &__title {
    font-size: 14px;
    line-height: 17px;
    color: #222;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 6/37.5rem;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10/37.5rem;
    background: #FFF6F3;
  }
  
  &__desc {
    flex: 1;
    overflow:hidden;
    text-overflow:ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin-right: 12/37.5rem;
    line-height: 13px;
    .img {
      width: 16/37.5rem;
      height: 16/37.5rem;
    }
    .text {
      margin-left: 4/37.5rem;
      font-size: 12px;
      line-height: 13px;
    }
  }
  &__btn {
    min-width: 44/37.5rem;
    max-width: 86/37.5rem;
    font-size: 11px;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #FFF;
    padding: 0 6/37.5rem;
    height: 23/37.5rem;
    line-height: 23/37.5rem;
    border-radius: 11.5/37.5rem;
    border: 0.5px solid #222;
    text-align: center;
  }
  &__close {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    padding-top: 4/37.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20/37.5rem;
  }
}
</style>
