<template>
  <div
    v-if="data"
    class="discount-detail__all"
  >
    <div class="discount-detail__header">
      <div class="discount-detail__header-content">
        <div class="discount-detail__header-title">
          {{ data.title }}
        </div>
        <div
          v-if="data.titleDesc"
          class="discount-detail__header-sub"
        >
          {{ data.titleDesc }}
        </div>
        <div 
          v-if="show && lureShowStyle == 2"
          class="discount-detail__header-induce"
        >
          <InduceItems :info="discountTopInfo" />
        </div>
      </div>
    </div>

    <DiscountTop
      v-if="show"
      :info="discountTopInfo" 
      :locals="locals"
      :showStyle="lureShowStyle"
    />
    <div
      v-if="show"
      class="discount-detail__content j-discount-detail-content"
    >
      <template
        v-for="(item, index) in data.items"
        :key="index"
      >
        <component
          :is="CompMap[item.type]"
          :ref="`${item.benefitType}Ref`"
          left-tip-bold
          :item="item"
          :data="item.data"
          :bottomView="data.bottomView"
          @open-tip="onOpenTip"
          @fold="isOpen => onFold(isOpen, item.benefitType)"
          @click-product-img="onClickProductImg"
        >
          <template #item-top="slotProps">
            <div
              v-if="slotProps.data.products && slotProps.data.products.length"
              class="discount-detail__product-list"
            >
              <ProductImg
                v-for="product in slotProps.data.products"
                :key="product.id"
                :src="product.goods_img"
                size="mini"
              />
            </div>
          </template>
        </component>
      </template>
    </div>

    <SLazyMount>
      <s-dialog
        v-model:visible="dialogShow"
        append-to-body
      >
        <template #title>
          <div v-html="dialogContent"></div>
        </template>
        <template #footer>
          <s-button
            width="100%"
            :type="['primary', 'H72PX']"
            @click="dialogShow =! dialogShow"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
    </SLazyMount>
  </div>
</template>

<script setup>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useStore } from '../../../hooks/useStore'
import ProductImg from '../components/ProductImg.vue'
import ChecklistCart from '../components/ChecklistCart.vue'
import ChecklistCell from '../components/ChecklistCell.vue'
import ChecklistDropdown from '../components/ChecklistDropdown.vue'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { triggerHighlightAnimation, scrollToElement } from '../../../utils/index.js'
import DiscountTop from '../components/DicountTop.vue'
import InduceItems from '../components/InduceItems'
import AddOnWrapper from '../components/AddOnWrapper.vue'
import { sendSaExposeScenesabtByDetailTips, sendSaExposeScenesabtByCartBundling } from 'public/src/pages/cart_v2/components/discountDetailDrawer/utils/analysis.js'

const CompMap = {
  1: ChecklistCart,
  2: ChecklistCell,
  3: ChecklistDropdown,
  5: AddOnWrapper,
}

const { state } = useStore()

const language = computed(() => state.language || {})
const locals = computed(() => state.locals)

const allDiscountDetailDrawerParams = computed(() => state.allDiscountDetailDrawerParams)
const showDiscountDetails = computed(() => allDiscountDetailDrawerParams.value.show)
const cartBundlingBiData = computed(() => state.mallCartBiData.cartBundlingBiData)
const data = computed(() => state.cartInfo?.promotionDetailPopUp ?? {})
const hasFlashSale = computed(() => {
  const aClassPromotions = data.value?.items?.find(item => item.benefitType === 'aClassPromotions')
  const bClassPromotions = data.value?.items?.find(item => item.benefitType === 'bClassPromotions')
  return aClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark) || bClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark)
})

const discountTopInfo = computed(() => {
  return data.value?.items?.find(item => item.type == 1)
})
const lureShowStyle = computed(() => {
  return discountTopInfo.value?.lureInfo?.style
})

const show = ref(false)
watch(() => showDiscountDetails.value, (val) => {
  setTimeout(async () => {
    show.value = val
    if (val) {
      sendSaExposeScenesabtByDetailTips({
        type: discountTopInfo.value?.lureInfo?.biType
      })
      cartBundlingBiData.value && sendSaExposeScenesabtByCartBundling({ cartBundlingBiData: cartBundlingBiData.value } )
    }
  }, 0)
}, { immediate: true })

const initAnchor = () => {
  watch([
    () => showDiscountDetails.value,
    () => allDiscountDetailDrawerParams.value.anchorId,
    () => allDiscountDetailDrawerParams.value.anchorType,
  ], ([isShow, id, type]) => {
    if (isShow && (id || type)) {
      setTimeout(() => {
        handleAnchorType()
      }, 0)
    }
  }, {
    immediate: true,
  })
}

const dialogShow = ref(false)
const dialogContent = ref('')
const onOpenTip = (text) => {
  dialogContent.value = text
  dialogShow.value = true
}

const onFold = (isOpen, benefitType) => {
  let type = ''
  if (['aClassPromotions', 'bClassPromotions'].includes(benefitType)) {
    type = 'promotion'
  }
  if (benefitType === 'coupon') {
    type = 'coupon'
  }

  daEventCenter.triggerNotice({
    daId: '1-8-7-64',
    extraData: {
      is_open: +isOpen,
      type,
    },
  })
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onClickProductImg = data => {
  const { id, checked } = data
  modifyCartCheckStatus(
    { cartIdList: [id], operationType: checked ? 1 : 2, type: 'item' },
    {
      click_select_someproduct: (defaultData) => {
        return {
          ...defaultData,
          position: 'popup',
        }
      }
    })
}

const couponRef = ref(null)
const aClassPromotionsRef = ref(null)
const bClassPromotionsRef = ref(null)

const handleScrollTo = (targetDom) => {
  scrollToElement({
    container: document.querySelector('.j-discount-detail-content'),
    target: targetDom,
    callback() {
      triggerHighlightAnimation(targetDom)
    },
  })
}
const handleCouponAnchor = async (id) => {
  const targetRef = couponRef.value?.[0]
  if (!targetRef) return
  const findTargetDom = () => {
    if (!id) return targetRef.$el.querySelector('.j-checklist-dropdown__content')
    return targetRef.$el.querySelector(`.j-checklist-dropdown__item-id-${id}`)
  }
  const isOpen = targetRef.$el.querySelector('.j-checklist-dropdown__content')
  !isOpen && targetRef.onClick?.()
  await nextTick()
  const targetDom = findTargetDom()
  handleScrollTo(targetDom)
}
const handleGiftAnchor = (promotionRef) => {
  const targetRef = promotionRef.value?.[0]
  if (!targetRef) return false
  const giftDoms = targetRef.$el.querySelector('.j-checklist-dropdown__item-2, .j-checklist-dropdown__item-4, .j-checklist-dropdown__item-28')
  if (giftDoms) {
    targetRef.onClick?.()
    setTimeout(() => {
      triggerHighlightAnimation(giftDoms)
    }, 300)
  }
  return !!giftDoms
}
const handlePromotionAnchor = async (promotionRef, id) => {
  const targetRef = promotionRef.value?.[0]
  if (!targetRef) return false
  const isOpen = targetRef.$el.querySelector('.j-checklist-dropdown__content')
  !isOpen && targetRef.onClick?.()
  await nextTick()
  const targetDom = targetRef.$el.querySelector(`.j-checklist-dropdown__item-id-${id}`)
  if (targetDom) {
    handleScrollTo(targetDom)
  }
  return !!targetDom
}
const handleAnchorType = () => {
  const type = allDiscountDetailDrawerParams.value.anchorType
  const id = allDiscountDetailDrawerParams.value.anchorId
  switch (type) {
    case 'promotion':
      if (!handlePromotionAnchor(aClassPromotionsRef, id)) handlePromotionAnchor(bClassPromotionsRef, id)
      break
    case 'coupon':
      handleCouponAnchor(id)
      break
    case 'gift':
      if (!handleGiftAnchor(aClassPromotionsRef)) handleGiftAnchor(bClassPromotionsRef)
      break
    default:
      break
  }
}

onMounted(() => {
  if(hasFlashSale.value){
    daEventCenter.triggerNotice({
      daId: '1-8-7-59',
    })
  }
  initAnchor()
})
</script>

<style lang="less" scoped>
@padding: 12/37.5rem;
@margin: 16/37.5rem;

.discount-detail {
  &__all {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    border-top-left-radius: 8/37.5rem;
    border-top-right-radius: 8/37.5rem;
    overflow: hidden;

    :deep(.checklist-cart__bar) {
      margin-bottom: @margin;
    }
    :deep(.checklist-cell) {
      margin-top: @margin;
      margin-bottom: @margin;
    }
    :deep(.checklist-cell__sub) {
      font-size: 12px;
    }
    :deep(.checklist-dropdown__bar) {
      margin-top: @margin;
      margin-bottom: @margin;
    }
    :deep(.checklist-cell .checklist-cell__right-price) {
      font-size: 16px;
    }
    :deep(.checklist-dropdown__content) {
      margin-top: @margin;
      margin-bottom: @margin;
      margin-left: -@padding;
      margin-right: -@padding;
      background: #f6f6f6;
    }
    :deep(.checklist-dropdown__item) {
      padding-left: @padding;
      padding-right: @padding;
      background: transparent;
    }
  }

  &__close {
    position: absolute;
    top: 8/37.5rem;
    right: 8/37.5rem;
  }

  &__header {
    flex: none;
  }

  &__header-content {
    padding: 12/37.5rem 0;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 510;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

  &__header-sub {
    margin-top: 4/37.5rem;
    padding: 0 8/37.5rem;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #ababab;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__header-induce {
    margin-top: 5/37.5rem;
    padding: 0 12/37.5rem;
    overflow: hidden;
  }

  &__content {
    padding: 0 @padding;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &__product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 7/37.5rem;
    margin-bottom: 8/37.5rem;
  }

  &__product-img {
    display: flex;
    align-items: center;
    width: 32/37.5rem;
    height: 32/37.5rem;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
</style>
